import React, { useEffect, useState } from 'react';
import { DELETE_MISC_DATA, GET_MISC_DATA, MAX_FILE_SIZE_MB, TOKEN_KEY, UPDATE_MISC_DATA, UPLOAD_EXTERNAL_STATIC_FILE } from '../config/Constants';
import { axiosInstance, setAuthToken } from './AxiosInstance';
import Loading from './Loading';

const Miscellaneous = ({setErrorMsg}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [dollarValueTier1, setDollarValueTier1] = useState(null);
    const [dollarValueTier2, setDollarValueTier2] = useState(null);
    const [messagesPageLinkKey, setMessagesPageLinkKey] = useState(null);
    const [newMessagesPageLink, setNewMessagesPageLink] = useState(null);
    const [newMessagesPageName, setNewMessagesPageName] = useState(null);
    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState(false);

    setAuthToken(localStorage.getItem(TOKEN_KEY));

    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Access the File object
        if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            setFileError('File size exceeds ' +MAX_FILE_SIZE_MB +' MB limit.');
            setFile(null);
          return;
        }
        setFile(file);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(file == null || file.size < 0){
            setFileError('File is empty');
            setFile(null);
            return;
        }
        setIsLoading(true);
        setFileError(null);
        const formData = new FormData();
        if (file) {
            formData.append('file', file);
        }
        try {
            await axiosInstance.put(UPLOAD_EXTERNAL_STATIC_FILE, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (error) {
            if(error.response.status === 415){
                setErrorMsg('This file is not supported');
            } else {
                setErrorMsg('Error uploading file: ' + error.response.data);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fetchData = async () => {
        setIsLoading(true);
        try{
            const miscData = await axiosInstance.get(GET_MISC_DATA);
            setDollarValueTier1(miscData.data.paidMember);
            setDollarValueTier2(miscData.data.paidMemberPlus);
            setMessagesPageLinkKey(miscData.data.messagesPageLinkKey);
        } catch (error){
            setErrorMsg("Failed to get misc data");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const updateDollarValueTier1 = async () => {
        try{
            const miscData = await axiosInstance.patch(UPDATE_MISC_DATA, {key: 'paidMember', value: dollarValueTier1});
            fetchData();
        } catch (error){
            setErrorMsg("Failed to update dollar value for paid member");
        }
    }

    const handleDollarTier1Change = (value) => {
        if(value < 0){
            setErrorMsg("Dollar value cannot be less than Zero")
            return;
        }
        setDollarValueTier1(value);
    }

    const updateDollarValueTier2 = async () => {
        try{
            const miscData = await axiosInstance.patch(UPDATE_MISC_DATA, {key: 'paidMemberPlus', value: dollarValueTier2});
            fetchData();
        } catch (error){
            setErrorMsg("Failed to update dollar value for paid member");
        }
    }

    const handleDollarTier2Change = (value) => {
        if(value < 0){
            setErrorMsg("Dollar value cannot be less than Zero")
            return;
        }
        setDollarValueTier2(value);
    }

    const handleMessagesPageLink = (key, link) => {
        setMessagesPageLinkKey(prevLinks => ({...prevLinks,[key]: link,})); // Update the specific key with the new input value
    }

    const updateMessageLink = async (key) => {
        if(key.length === 0 || messagesPageLinkKey[key] == null || messagesPageLinkKey[key].length === 0){
            return;
        }
        try{
            const miscData = await axiosInstance.patch(UPDATE_MISC_DATA, {key: key, value: messagesPageLinkKey[key]});
            fetchData();
        } catch (error){
            setErrorMsg("Failed to update message page links");
        }
    }

    const deleteMessageLink = async (key) => {
        if(key.length === 0){
            return;
        }
        try{
            const miscData = await axiosInstance.delete(DELETE_MISC_DATA, {params: {key: `messagesPageLinkKey${key}`}});
            fetchData();
        } catch (error){
            setErrorMsg("Failed to update message page links");
        }
    }

    const createNewMessageLink = async () => {
        if(newMessagesPageLink == null || newMessagesPageLink.length === 0){
            return;
        }
        try{
            const miscData = await axiosInstance.patch(UPDATE_MISC_DATA, {key: `messagesPageLinkKey${newMessagesPageName}`, value: newMessagesPageLink});
            fetchData();
        } catch (error){
            setErrorMsg("Failed to delete new message page links");
        }
    }

    if(isLoading === true) return <Loading />;
    return (
        <div className="page-container display-grid" >
            <div className="container max-content" style={{minWidth: "60vw"}}>
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="d-flex align-items-center">
                            {dollarValueTier1 && (
                                <>
                                    <label className="me-2" htmlFor="cent">Dollar value for Patron Paid Member</label>
                                    <input
                                        type="number"
                                        className="form-control mx-2"
                                        id="cent"
                                        onChange={e => handleDollarTier1Change(e.target.value)}
                                        value={dollarValueTier1}
                                        style={{ width: "100px" }}
                                    />
                                    <button
                                        type="submit"
                                        className="btn btn-primary ms-2"
                                        disabled={isLoading}
                                        onClick={updateDollarValueTier1}
                                    >
                                        {isLoading ? 'Updating...' : 'Update'}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="col-12 mb-4">
                        <div className="d-flex align-items-center">
                            {dollarValueTier2 && (
                                <>
                                    <label className="me-2" htmlFor="cent2">Dollar value for Patron Paid Member Plus</label>
                                    <input
                                        type="number"
                                        className="form-control mx-2"
                                        id="cent2"
                                        onChange={e => handleDollarTier2Change(e.target.value)}
                                        value={dollarValueTier2}
                                        style={{ width: "100px" }}
                                    />
                                    <button
                                        type="submit"
                                        className="btn btn-primary ms-2"
                                        disabled={isLoading}
                                        onClick={updateDollarValueTier2}
                                    >
                                        {isLoading ? 'Updating...' : 'Update'}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>

                    {messagesPageLinkKey && Object.entries(messagesPageLinkKey).map(([key, link]) => (
                        <div key={key} className="col-12 mb-4">
                            <div className="d-flex align-items-center">
                                <label className="me-2" htmlFor={key}>{key}</label>
                                <input
                                    type="text"
                                    className="form-control mx-2"
                                    id={key}
                                    onChange={e => handleMessagesPageLink(link, e.target.value)}
                                    value={link}
                                    style={{ width: "500px" }}
                                />
                                <button
                                    type="submit"
                                    className="btn btn-primary ms-2"
                                    disabled={isLoading}
                                    onClick={() => updateMessageLink(key)}
                                >
                                    {isLoading ? 'Adding...' : 'Update'}
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-danger ms-2"
                                    disabled={isLoading}
                                    onClick={() => deleteMessageLink(key)}
                                >
                                    {isLoading ? 'Deleting...' : 'Delete'}
                                </button>
                            </div>
                        </div>
                    ))}

                    <div className="col-12 mb-4">
                        <div className="d-flex align-items-center">
                            <label className="me-2" htmlFor="newMessagePageLink">Add New Message Link</label>
                            <input
                                placeholder="Link Name"
                                type="text"
                                className="form-control mx-2"
                                id="newMessagePageName"
                                onChange={e => setNewMessagesPageName(e.target.value)}
                                style={{ width: "300px" }}
                            />
                            <input
                                placeholder="Add link here"
                                type="text"
                                className="form-control mx-2"
                                id="newMessagePageLink"
                                onChange={e => setNewMessagesPageLink(e.target.value)}
                                style={{ width: "300px" }}
                            />
                            <button
                                type="submit"
                                className="btn btn-primary ms-2"
                                disabled={isLoading}
                                onClick={createNewMessageLink}
                            >
                                {isLoading ? 'Adding...' : 'Add'}
                            </button>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex align-items-center">
                            <label className="me-2" htmlFor="file">External static file (Max {MAX_FILE_SIZE_MB} MB)</label>
                            <input
                                type="file"
                                className="form-control my-2"
                                id="file"
                                accept='text/*'
                                onChange={handleFileChange}
                                style={{ width: "300px" }}
                            />
                            {fileError && <p className="text-danger">{fileError}</p>}

                            <button 
                                type="submit" 
                                className="btn btn-primary ms-2"
                                disabled={isLoading}
                                onClick={handleSubmit}
                                >
                                {isLoading ? 'Uploading....' : 'Add File'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

    </div>
    );
};

export default Miscellaneous;