import React from 'react'
import {FaSortUp, FaSortDown, FaSort} from "react-icons/fa6";

function SortIcon({isActive, isAscending}) {
  if (isActive) {
    return isAscending ? <FaSortUp color='black'/> : <FaSortDown color='black'/>
  }else {
    return <FaSort color="grey"/>
  }
}

export default SortIcon
