import React, { useState } from "react";
import axios from "axios";
import { REGISTER_API, ACTIVATE_REGISTERED_USER, RESEND_OTP_API } from "../config/Constants";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import OtpHandler from "./OtpHandler";
import Loading from "./Loading";

const Register = ({ setErrorMsg, setIfSuccess }) => {
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isRegistered, setIsRegistered] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setPasswordMatch(false);
      setErrorMsg("Password and Confirm Password do not match");
      return;
    }

    try {
      setDisableButtons(true);
      const postData = { username, password, confirmPassword };
      const registerResp = await axios.post(REGISTER_API, postData);

      if (registerResp.status === 200) {
        setIfSuccess(true);
        setErrorMsg("Unverified users will be deleted after 24 hours");
        setIsRegistered(true);
      } else {
        setErrorMsg(registerResp.data);
      }
    } catch (error) {
      console.log(error);
      setErrorMsg("Failed to register user, please try again later.");
    }
    setDisableButtons(false);
  };

  if(isRegistered){
    return <OtpHandler 
              username={username} 
              verifyOtpApi={ACTIVATE_REGISTERED_USER} 
              enableOtpSubmitParam={true}
              resendOtpApi={RESEND_OTP_API} 
              onSuccess={() => {
                setIfSuccess(true);
                setErrorMsg("Regsitration completed with OTP verification, you can login now");
                navigate("/");
              }} />;
  }

  return (
    <div className="page-container display-grid">
      <div className="max-content">
        <h3 className="card-title text-center">Register</h3>
        <p>Please enter your details</p>
        <form onSubmit={handleFormSubmit}>
          <div className="form-group text-start">
            <label htmlFor="username">Username</label>
            <input
              type="email"
              className="form-control"
              id="username"
              placeholder="Enter email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <small className="text-muted">Note: This field is case sensitive.</small>
            {!validateEmail(username) && username.length > 0 && (
              <p className="text-danger">Please enter a valid email address</p>
            )}
          </div>

          <div className="form-group text-start">
            <label htmlFor="password">Password</label>
            <div className="input-group">
              <input
                type={showPassword1 ? "text" : "password"}
                className="form-control"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div className="input-group-append">
                <span className="input-group-text h-100">
                  {showPassword1 ? (
                    <FaEye onClick={() => setShowPassword1(!showPassword1)} />
                  ) : (
                    <FaEyeSlash onClick={() => setShowPassword1(!showPassword1)} />
                  )}
                </span>
              </div>
            </div>
            {password.length > 0 && password.length < 8 && (
              <p className="text-danger">Password must be at least 8 characters</p>
            )}
          </div>

          <div className="form-group text-start">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <div className="input-group">
              <input
                type={showPassword2 ? "text" : "password"}
                className="form-control"
                id="confirmPassword"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setPasswordMatch(true);
                }}
                required
                style={{ borderColor: passwordMatch ? "#ced4da" : "red" }}
              />
              <div className="input-group-append">
                <span className="input-group-text h-100">
                  {showPassword2 ? (
                    <FaEye onClick={() => setShowPassword2(!showPassword2)} />
                  ) : (
                    <FaEyeSlash onClick={() => setShowPassword2(!showPassword2)} />
                  )}
                </span>
              </div>
            </div>
          </div>

          <button type="submit" className="btn btn-primary btn-block mt-3" disabled={disableButtons}>Register</button>
        </form>
      </div>
    </div>
  );
};

export default Register;