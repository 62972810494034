import React from 'react';
import './style/MaintenancePage.css';

const MaintenancePage = () => {
  return (
    <div className="container">
      <div className="header">Site Under Maintenance</div>
      <div className="note">
        We apologize for the inconvenience. Our site is currently undergoing scheduled maintenance.
      </div>
      <div className="footer">
        Please check back later.
      </div>
    </div>
  );
};
export default MaintenancePage;