import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Loading from "./Loading";

const OtpHandler = ({ username, verifyOtpApi, resendOtpApi, onSuccess, enableOtpSubmitParam}) => {
  const [otpInput, setOtpInput] = useState("");
  const [otpTimer, setOtpTimer] = useState(300); // 5 minutes
  const [failCount, setFailCount] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const timerRef = useRef(null); // Store interval reference
  const [enableOtpSubmit, setEnableOtpSubmit] = useState(enableOtpSubmitParam);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(!enableOtpSubmit){
        return;
    }
    startOtpTimer();
    return () => clearInterval(timerRef.current); // Cleanup on unmount
  }, []);

  useEffect(() => {
    if (otpTimer === 0) {
      clearInterval(timerRef.current); // Stop timer when it reaches zero
    }
  }, [otpTimer]);

  const startOtpTimer = () => {
    clearInterval(timerRef.current); // Ensure only one timer runs
    setOtpTimer(300); // Reset timer to 5 minutes

    timerRef.current = setInterval(() => {
      setOtpTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  const handleOtpSubmit = async () => {
    if (otpInput.length === 6) {
        setLoading(true);
      try {
        const postData = { username, otp: otpInput };
        const response = await axios.post(verifyOtpApi, postData);
        if (response.status === 202) {
          onSuccess();
        } else {
          setFailCount((prevCount) => prevCount + 1);
          setErrorMsg("Invalid OTP. Please try again.");
        }
      } catch (error) {
        setFailCount((prevCount) => prevCount + 1);
        setErrorMsg("Invalid OTP. Please try again.");
      }
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setLoading(true);
    try {
      const response = await axios.post(resendOtpApi, username, {
        headers: { "Content-Type": "text/plain" },
      });
      if (response.status === 200) {
        setFailCount(0); // Reset fail count on resend
        setErrorMsg("");
        startOtpTimer(); // Reset timer only on resend
        setEnableOtpSubmit(true);
      } else {
        setErrorMsg("Failed to resend OTP.");
      }
    } catch (error) {
      setErrorMsg("Failed to resend OTP.");
    }
    setLoading(false);
  };

  if (loading) return <Loading />;

  return (
    <div className="page-container display-grid">
      <div className="max-content">
        { enableOtpSubmit ? (
                <>
                    <label htmlFor="otpInput">Enter OTP (Time left: {formatTime(otpTimer)})</label>
                    <input
                        type="text"
                        className="form-control"
                        id="otpInput"
                        placeholder="Enter OTP"
                        value={otpInput}
                        onChange={(e) => setOtpInput(e.target.value)}
                        maxLength={6}
                        required
                        disabled={failCount >= 3} // Disable input after 3 failures
                    />
                    {errorMsg && <p className="text-danger mt-2">{errorMsg}</p>}
            
                    {failCount < 3 ? (
                    <button className="btn btn-primary mt-2" type="button" onClick={handleOtpSubmit}>
                        Submit OTP
                    </button>
                    ) : (
                    <p className="text-danger">You have exceeded the maximum attempts. Please request a new OTP.</p>
                )}
                </>
            ) : (
                <>
                    <p>Send OTP to the email for verification</p>
                </>
            )
        }

      <button className="btn btn-secondary mt-2" type="button" onClick={handleResendOtp}>
        Resend OTP
      </button>
    </div>
    </div>
  );
};

export default OtpHandler;