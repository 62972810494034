import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: window.BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

// Function to set JWT token in the Authorization header
const setAuthToken = (token) => {
    if (token) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer=${token}`;
    } else {
        delete axiosInstance.defaults.headers.common['Authorization'];
    }
};

export { axiosInstance, setAuthToken };
