import React, { useEffect, useState } from 'react';
import { ALL_IMAGES_URL_MAP_API, DELETE_IMAGE_API, TOKEN_KEY } from '../config/Constants';
import { axiosInstance, setAuthToken } from './AxiosInstance';
import Loading from './Loading';
import { Card, Col, Row } from 'react-bootstrap';

const ImageList = ({ setErrorMsg }) => {
    const [imageList, setImageList] = useState({});
    const [imageLoading, setImageLoading] = useState(true);

    const deleteImage = (imageKey) => {
        setAuthToken(localStorage.getItem(TOKEN_KEY));
        axiosInstance.delete(DELETE_IMAGE_API, { params: { imageKey: imageKey } })
            .then(resp => {
                const newImageList = { ...imageList };
                delete newImageList[imageKey];
                setImageList(newImageList);
            })
            .catch(e => {
                if(e.response.status === 403){
                    setErrorMsg("This image is not allowed to be deleted");
                } else {
                    setErrorMsg("Failed to delete image");
                }
            });
    };

    useEffect(() => {
        const fetchData = async () => { 
            try {
                setAuthToken(localStorage.getItem(TOKEN_KEY));
                const imageResp = await axiosInstance.get(ALL_IMAGES_URL_MAP_API);
                if (imageResp.status === 200) {
                    setImageList(imageResp.data);
                } else {
                    setErrorMsg(imageResp.data);
                }
            } catch (error) {
                setErrorMsg("Failed to load images");
            } finally {
                setImageLoading(false);
            }
        };

        fetchData();
    }, []);

    if (imageLoading) return <Loading />; // Show loading indicator while fetching data

    return (
        <>
            <Row className="mb-4 bold-text">
                <Col md={3} sm={3}>Image Name</Col>
                <Col md={9} sm={9}>Image</Col>
            </Row>
            {imageList && Object.entries(imageList).map(([imageName, imageUrl], index) => (
                <Row key={index} className={index % 2 !== 0 ? 'striped-row-even my-4 py-2' : 'striped-row-odd my-4 py-2'}>
                    <Col md={3} sm={3}>
                        <Row className='justify-content-center'>{imageName}</Row>
                        <Row>
                            <a className='justify-content-center cursor-pointer' onClick={() => { deleteImage(imageName) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash mt-4" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                </svg>
                            </a>
                        </Row>
                    </Col>
                    <Col md={9} sm={9}>
                        <Card key={imageName}>
                            <Card.Img variant="top" src={imageUrl} alt={imageName} onContextMenu={(e) => e.preventDefault()} />
                        </Card>
                    </Col>
                </Row>
            ))}
        </>
    );
};

export default ImageList;