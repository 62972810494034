import { React } from "react";
import { Dropdown, Button } from "react-bootstrap";
import { FaFilter } from "react-icons/fa6";

function FilterComponent({ filterBy, filterValues, selectedFilters, onChecked }) {

  const handleOnChecked = (value) => {
    const updatedSelectedFilters = selectedFilters.includes(value)
      ? selectedFilters.filter(item => item !== value)
      : [...selectedFilters, value];

    onChecked(filterBy, updatedSelectedFilters);
  }

  return (
    <>
      {filterBy && (
        <Dropdown>
          <Dropdown.Toggle as={Button} variant={selectedFilters.length > 0 ? "primary" : "secondary"} size="sm">
            <FaFilter />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {Array.isArray(filterValues) && filterValues.length > 0 && (
              filterValues.map((value, index) => (
                <label
                  key={index}
                  htmlFor={`${filterBy}-${index}`}
                  className="w-100 dropdown-item cursor-pointer"
                >
                  <input
                    type="checkbox"
                    id={`${filterBy}-${index}`}
                    checked={selectedFilters?.includes(value)}
                    name={filterBy}
                    value={value}
                    onChange={() => handleOnChecked(value)}
                  />
                  {" " + value}
                </label>
              ))
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}

export default FilterComponent;
