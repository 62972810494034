import React, { useState, useEffect } from 'react';
import Loading from './Loading';
import { axiosInstance, setAuthToken } from './AxiosInstance';
import { PATRON_INFO_API, TOKEN_KEY, UPDATE_PATRON_INFO_AUTO_API } from '../config/Constants';
import { Col, Container, Row } from 'react-bootstrap';
import { saveAs } from 'file-saver';

const TokenInfo = ({setErrorMsg}) => {
  const [tokenData, setTokenData] = useState(null);

  const fetchTokenInfo = async () => {
    try{
        setAuthToken(localStorage.getItem(TOKEN_KEY));
        const respData = await axiosInstance.get(PATRON_INFO_API);
        setTokenData(respData.data);
    } catch (error) {
        setErrorMsg("Failed to get patron details for the app");
    }
};

const updateTokenAutomatically = async () => {
    try{
        setAuthToken(localStorage.getItem(TOKEN_KEY));
        await axiosInstance.post(UPDATE_PATRON_INFO_AUTO_API);
        fetchTokenInfo();
    } catch (error) {
        setErrorMsg("Failed to update patron token");
    }

}

const downloadJson = () => {
    const jsonString = JSON.stringify(tokenData, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    saveAs(blob, "token-info.json");
}

  // Fetch data from API or some source
  useEffect(() => {
    fetchTokenInfo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTokenData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Post the updated data to the API
    try {
      const response = await fetch('api/your-endpoint', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(tokenData)
      });
      const result = await response.json();
      console.log('Success:', result);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if(tokenData == null) return <Loading />;

  return (
    <>
      <h2>Token Information</h2>
      <form onSubmit={handleSubmit}>
            {Object.keys(tokenData).map((key) => (
              <Row key={key}>
                <Col md={2} sm={2}>
                    <strong>{key}</strong>
                </Col>
                <Col md={10} sm={10}>
                  <textarea
                    type="text"
                    name={key}
                    value={tokenData[key] != null ? tokenData[key] : ''}
                    onChange={handleChange}
                    className='w-100'
                  />
                </Col>
              </Row>
            ))}
            <Row className='mt-4'>
                <Col md={4} sm={4} >
                    <button type="submit" className="btn btn-primary w-100" onClick={updateTokenAutomatically}>Update Automatically</button>
                </Col>
                <Col md={4} sm={4} >
                    <button type="submit" className="btn btn-danger w-100">Update Manually</button>
                </Col>
                <Col md={4} sm={4} >
                    <button type="btn" className="btn btn-success w-100" onClick={downloadJson}>Save Backup</button>
                </Col>
            </Row>
      </form>
      </>
  );
};

export default TokenInfo;
