import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { LOGIN_API, TOKEN_KEY, ACTIVATE_REGISTERED_USER, RESEND_OTP_API, WEBSIE_LOGO } from "../config/Constants";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import OtpHandler from "./OtpHandler";
import Loading from "./Loading";

const Login = ({ setIsLoggedIn, setErrorMsg, logoImageSrc, setIfSuccess }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [requiresOtp, setRequiresOtp] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    setErrorMsg(null);

    if (username && password) {
      try {
        const postData = { username, password };
        const loginResponse = await axios.post(LOGIN_API, postData);

        if (loginResponse.status === 200) {
          localStorage.setItem(TOKEN_KEY, loginResponse.data);
          setIsLoggedIn(true);
          navigate("/");
        } else {
          setErrorMsg(loginResponse.data);
        }
      } catch (error) {
        if (error.response?.status === 403) {
          if (error.response.data?.toLowerCase() === "this account is not activated with otp") {
            setErrorMsg("Your account is not activated. Please validate with OTP.");
            setRequiresOtp(true);
          }
        } else if (error.response?.status === 423) {
          setErrorMsg(error.response.data);
        } else {
          setErrorMsg("Failed to login user, please use correct username and password");
        }
      }
    }
    setLoading(false);
  };

  if (loading) return <Loading />;

  if(requiresOtp) {
    return <OtpHandler
      username={username}
      verifyOtpApi={ACTIVATE_REGISTERED_USER}
      resendOtpApi={RESEND_OTP_API}
      enableOtpSubmitParam={false}
      onSuccess={() => {
        setIsLoggedIn(false);
        setRequiresOtp(false);
        setIfSuccess(true);
        setErrorMsg("OTP verification completed, you can login now");
        navigate("/");
      }}
    />;
  }

  return (
    <div className="page-container display-grid">
      <div className="max-content">
        <div className="text-center mb-4">
          <img src={WEBSIE_LOGO} alt="logo" className="img-fluid" style={{ maxWidth: "150px" }} />
        </div>
        <h2 className="text-center mb-3">Welcome back!</h2>
        <p className="text-center mb-4">Please enter your details</p>

          <form onSubmit={handleFormSubmit}>
            <div className="form-group mb-3">
              <label htmlFor="username">Username</label>
              <input
                type="email"
                className="form-control"
                id="username"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="password">Password</label>
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div className="input-group-append">
                  <span className="input-group-text h-100" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Link to="/forgot-password" className="text-decoration-none" >
                Forgot password?
              </Link>
            </div>
            <Row className="text-center">
              <Col md={6} sm={6}>
                <button type="submit" className="btn btn-success btn-block w-100" >
                  Log In
                </button>
              </Col>
              <Col md={6} sm={6}>
                <Link to="/register" className="btn btn-danger btn-block w-100" >
                  Sign Up
                </Link>
              </Col>
            </Row>
          </form>
      </div>
    </div>
  );
};

export default Login;