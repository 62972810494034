import React, { useEffect, useState } from 'react';
import { axiosInstance, setAuthToken } from './AxiosInstance';
import { CONTROL_APP_API, LOYALTY_GAME_API, TOKEN_KEY } from '../config/Constants';
import Loading from './Loading';
import MaintenancePage from './MaintenancePage';
import WorkInProgress from './WorkInProgress';

const ControllerApp = ({ activePage, setErrorMsg }) => {
    const [htmlUrl, setHtmlUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isHtmlFetchError, setIsHtmlFetchError] = useState(false);
    const [isWorkInProgress, setIsWorkInProgress] = useState(false);
    let EXTERNAL_API = null;
    
    useEffect(() => {
        const fetchHtmlPage = async () => {
            setIsLoading(true);
            setIsHtmlFetchError(false);
            setIsWorkInProgress(false);
            if(activePage === 4 && EXTERNAL_API !== CONTROL_APP_API){
                EXTERNAL_API = CONTROL_APP_API;
            } else if (activePage === 5 && EXTERNAL_API !== LOYALTY_GAME_API){
                EXTERNAL_API = LOYALTY_GAME_API;
            } else {
                return;
            }

            try {
                setAuthToken(localStorage.getItem(TOKEN_KEY));
                const response = await axiosInstance.get(EXTERNAL_API, {
                    headers: { 'Content-Type': 'text/html' }
                });
                if(response.status === 200){
                    const blob = new Blob([response.data], { type: 'text/html' });
                    const url = URL.createObjectURL(blob);
                    setHtmlUrl(url);
                } else if(response.status === 204){
                    setIsWorkInProgress(true);
                } else {
                    setIsHtmlFetchError(true);
                }
            } catch (error) {
                setIsHtmlFetchError(true);
                if (error.response) {
                    if (error.response.status === 423) {
                        setErrorMsg(error.response.data);
                    } else if (error.response.status === 403) {
                        setErrorMsg('You are not authorized for this gaming controller');
                    } else {
                        setErrorMsg('Error loading game page');
                    }
                } else {
                    setErrorMsg('Network error');
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchHtmlPage();
    }, [activePage]);

    if (isLoading) return <Loading />;
    if (isHtmlFetchError) return <MaintenancePage />;
    if (isWorkInProgress) return <WorkInProgress />;

    return (
        <iframe
            src={htmlUrl}
            width="100%"
            height="94%"
            title="Controller Page"
            style={{ border: 'none' }}
        />
    );
};

export default ControllerApp;