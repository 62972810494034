import React, { useState, useEffect } from 'react';
import { ADD_IMAGE_TO_STORY, ADD_VIDEO_TO_STORY, ALL_IMAGES_API, ALL_STORIES, ALL_VIDEOS_API, AVAILABLE_ROLES, DELETE_STORY_API, REMOVE_IMAGE_TO_STORY, REMOVE_VIDEO_TO_STORY, TOKEN_KEY, UPDATE_STORY_ACCESSOR, UPDATE_STORY_ASSET_URL } from '../config/Constants';
import Loading from './Loading';
import { axiosInstance, setAuthToken } from './AxiosInstance';
import { DateUtils } from '../config/DateUtils';
import { Row, Col , Modal, Button} from 'react-bootstrap'; // Import Row and Col from react-bootstrap
import SortIcon from './SortIcon';
import FilterComponent from './FilterComponent';

const StoriesTable = ({ setErrorMsg }) => {
  const [storiesData, setStoriesData] = useState(null);
  const [imageList, setImageList] = useState(null);
  const [videoList, setVideoList] = useState(null);
  const [availableRoles, setAvailableRoles] = useState(null);
  setAuthToken(localStorage.getItem(TOKEN_KEY));
  const [sortState, setSortState] = useState({sortBy: "id", ascending: true});
  const [appliedFilters, setAppliedFilters] = useState({
    accessor: []
  });
  const [showWizard, setShowWizard] = useState(false);
  const [storyIdForUpdate, setStoryIdForUpdate] = useState(null);
  const [storyAssetUrl, setStoryAssetUrl] = useState(null);
  const [isUpdatingStoryAssetUrl, setIsUpdatingStoryAssetUrl] = useState(false);

  const filteredStories = storiesData?.filter(story => {
    return Object.entries(appliedFilters).every(([key, values]) =>
      values.length > 0 ? values.includes(story[key]) : true
    )
  })

  const handleSort = (sortBy) => {
    setSortState(prevSortState => {
      if (prevSortState.sortBy === sortBy) {
        return {...prevSortState, ascending: !prevSortState.ascending}
      }else {
        return {...prevSortState, sortBy, ascending: true}
      }
    })
  }

  const sort = () => {
    if (!storiesData) return;

    const sorted = [...storiesData].sort((a, b) => {
      if (sortState.sortBy === "id") {
        return sortState.ascending ? a.id - b.id : b.id - a.id;
      } else if (sortState.sortBy === "heading") {
        return sortState.ascending
          ? a.heading.localeCompare(b.heading)
          : b.heading.localeCompare(a.heading);
      } else if (sortState.sortBy === "creationTime" || sortState.sortBy === "updateTime") {
        return sortState.ascending
          ? new Date(a[sortState.sortBy]) - new Date(b[sortState.sortBy])
          : new Date(b[sortState.sortBy]) - new Date(a[sortState.sortBy]);
      }
      return 0;
    });

    setStoriesData(sorted);
  };

  useEffect(() => {
    sort();
  }, [sortState])
  
  const handleFilterChange = (filterBy, checkedFilters) => {
    setAppliedFilters(prev => ({
      ...prev,
      [filterBy]: checkedFilters
    }));
  }

  const deleteStory = (id) => {
    axiosInstance.delete(DELETE_STORY_API, { params: { storyId: id } })
      .then(resp => {
        const newStoriesObject = storiesData.filter(item => item.id !== id);
        setStoriesData(newStoriesObject);
      })
      .catch(e => setErrorMsg("Failed to delete story"));
  }

  const fetchStories = async () => {
    try {
      const response = await axiosInstance.get(ALL_STORIES);
      if (response.status === 200) {
        setStoriesData(response.data);
      }
    } catch (error) {
      console.log(error);
      setErrorMsg("Page loading failed");
    }
  };

  const handleRemoveImage = (storyId, imageId) => {
    axiosInstance.delete(REMOVE_IMAGE_TO_STORY, {data: {storyId: storyId, imageId: imageId}})
    .then(resp => {
      fetchStories();
    })
    .catch(error => {
      if(error.response.status === 404){
        setErrorMsg("Image not found with the story mentioned");
      } else {
        setErrorMsg("Failed to delete image from the story");
      }      
    });
  };

  const handleRemoveVideo = (storyId, videoKey) => {
    axiosInstance.delete(REMOVE_VIDEO_TO_STORY, {data: {storyId: storyId, videoKey: videoKey}})
    .then(resp => {
      fetchStories();
    })
    .catch(error => {
      if(error.response.status === 404){
        setErrorMsg("Video not found with the story mentioned");
      } else {
        setErrorMsg("Failed to delete video from the story");
      }      
    });
  };

  const handleAddImage = (storyId, imageKey) => {
    axiosInstance.put(ADD_IMAGE_TO_STORY, {storyId: storyId, imageKey: imageKey})
    .then(resp => {
      fetchStories();
    })
    .catch(error => {
      if(error.response.status === 403){
        setErrorMsg("This image cannot be added to stories");
      } else if(error.response.status === 404){
        setErrorMsg("Image or story details not found");
      } else if(error.response.status === 409){
        setErrorMsg("Image already present in the story");
      } else {
        setErrorMsg("Failed to add image to the story");
      }
    });
  };

  const handleAddVideo = (storyId, videoKey) => {
    axiosInstance.put(ADD_VIDEO_TO_STORY, {storyId: storyId, videoKey: videoKey})
    .then(resp => {
      fetchStories();
    })
    .catch(error => {
      if(error.response.status === 403){
        setErrorMsg("This video cannot be added to stories");
      } else if(error.response.status === 404){
        setErrorMsg("Video or story details not found");
      } else if(error.response.status === 409){
        setErrorMsg("Video already present in the story");
      } else {
        setErrorMsg("Failed to add video to the story");
      }
    });
  };

  const fetchImage = async () => {
    try {
        const imageResp = await axiosInstance.get(ALL_IMAGES_API);
        if (imageResp.status === 200) {
            setImageList(imageResp.data);
        } else {
            setErrorMsg(imageResp.data);
        }
    } catch (error) {
        setErrorMsg("Failed to fetch all images list");
    }
  };

  const fetchVideo = async () => {
    try {
        const imageResp = await axiosInstance.get(ALL_VIDEOS_API);
        if (imageResp.status === 200) {
            setVideoList(imageResp.data);
        } else {
            setErrorMsg(imageResp.data);
        }
    } catch (error) {
        setErrorMsg("Failed to fetch all videos list");
    }
  };

const updateStoryAccessor = async (storyId, role) => {
  try{
      const respData = await axiosInstance.patch(UPDATE_STORY_ACCESSOR, {id: storyId, userRole: role});
  } catch (error) {
      setErrorMsg("Failed to update story role accessibility");
  }
  fetchStories();
}

const updateStoryAssetUrl = async () => {
  try {
    setIsUpdatingStoryAssetUrl(true);
    const updateAssetUrl = await axiosInstance.put(UPDATE_STORY_ASSET_URL, null, {params: {storyId: storyIdForUpdate, assetUrl: storyAssetUrl}});
    handleWizardClose();
  } catch (error) {
      setErrorMsg("Failed to update story asset");
  }
  fetchStories();
}

const fetchAvailableRoles = async () => {
  try{
      const respData = await axiosInstance.get(AVAILABLE_ROLES);
      setAvailableRoles(respData.data);
  }catch(error){
      setErrorMsg("Error getting available roles options");
  }
}
  useEffect(() => {
    fetchStories(); 
    fetchImage();
    fetchVideo();
    fetchAvailableRoles();
  }, []);

  const handleWizardClose = () => {
    setShowWizard(false);
    setIsUpdatingStoryAssetUrl(false);
    setStoryIdForUpdate(null);
    setStoryAssetUrl(null);
  };
  const wizard = <Modal show={showWizard} onHide={handleWizardClose} centered style={{cursor: "pointer"}}>
                  <Modal.Header closeButton>
                  <Modal.Title>Update Story Asset URL</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <textarea
                      style={{width: "100%"}}
                      value={storyAssetUrl}
                      onChange={(e) => setStoryAssetUrl(e.target.value)}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                  <Button variant="secondary" onClick={handleWizardClose}>
                      Cancel
                  </Button>
                  <Button variant="primary" onClick={updateStoryAssetUrl} disabled={isUpdatingStoryAssetUrl} >
                    {isUpdatingStoryAssetUrl ? "Updating..." : "Update"}
                  </Button>
                  </Modal.Footer>
                </Modal>;

  const editStoryAssetUrl = (storyId, storyAssetUrl) => {
    setShowWizard(true);
    setStoryIdForUpdate(storyId);
    setStoryAssetUrl(storyAssetUrl);
  }

  if (storiesData == null || imageList == null) return <Loading />;

  return (
    <>
      <Col md={12} sm={12}>
        <Row key='-1' className="my-2 bold-text">
            <Col md={1} sm={1} className='cursor-pointer' onClick={() => handleSort("id")}>
              ID <SortIcon isActive={sortState.sortBy === "id"} isAscending={sortState.ascending}/>
            </Col>
            <Col md={2} sm={2} className='cursor-pointer' onClick={() => handleSort("heading")}>
              Heading <SortIcon isActive={sortState.sortBy === "heading"} isAscending={sortState.ascending}/>
            </Col>
            <Col md={1} sm={1}>Asset URL</Col>
            <Col md={1} sm={1} className='cursor-pointer'>
              Accessor
              <FilterComponent 
                filterBy="accessor" 
                filterValues={availableRoles ? [...availableRoles] : []}
                selectedFilters={appliedFilters["accessor"]}
                onChecked={handleFilterChange}
              />
            </Col>
            <Col md={1} sm={1} className='cursor-pointer' onClick={() => handleSort("creationTime")}>
              Creation Time <SortIcon isActive={sortState.sortBy === "creationTime"} isAscending={sortState.ascending}/>
            </Col>
            <Col md={1} sm={1} className='cursor-pointer' onClick={() => handleSort("updateTime")}>
              Update Time <SortIcon isActive={sortState.sortBy === "updateTime"} isAscending={sortState.ascending}/>
            </Col>
            <Col md={2} sm={2}>Image IDs</Col>
            <Col md={2} sm={2}>Video Keys</Col>
            <Col md={1} sm={1} className='justify-content-center'>Actions</Col>
        </Row>
      </Col>
      {showWizard && wizard}
      <Col md={12} sm={12}>
        {filteredStories?.map((story, index) => (
          <Row key={story.id} className={index % 2 !== 0 ? 'striped-row-even my-4 py-2' : 'striped-row-odd my-4 py-2'}>

            <Col md={1} sm={1}>{story.id}</Col>
            <Col md={2} sm={2}>{story.heading}</Col>
            <Col md={1} sm={1}>
              <div style={{cursor: "pointer"}} onClick={()=>{editStoryAssetUrl(story.id, story.assetUrl)}}>
                {story.assetUrl}
              </div>
            </Col>
            <Col md={1} sm={1}>
                            <select value={story.accessor} onChange={(e) => {updateStoryAccessor(story.id, e.target.value)}} className="form-control" required>
                                {availableRoles &&
                                    availableRoles.map((role) => <option key={role} value={role}>{role}</option>)
                                }
                            </select>
            </Col>
            <Col md={1} sm={1}>{DateUtils.formatDateTime(story.creationTime)}</Col>
            <Col md={1} sm={1}>{DateUtils.formatDateTime(story.updateTime)}</Col>
            <Col md={2} sm={2}>
              <Row>
                {story.imageKeySet && story.imageKeySet.map(imageKey => 
                  <ul key={imageKey}>
                    ({imageKey})
                    <a onClick={() => handleRemoveImage(story.id, imageKey)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16" style={{color: 'red'}}>
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                      </svg>
                    </a>
                  </ul>
                )}
              </Row>
              <Row className='image-options'>
                {imageList && imageList.map(eachImage => 
                  <ul key={eachImage} className='my-0'>
                    ({eachImage})
                    <a onClick={() => handleAddImage(story.id, eachImage)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square-fill" viewBox="0 0 16 16" style={{color: 'green'}}>
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0"/>
                      </svg>
                    </a>
                    </ul>
                )}
              </Row>
            </Col>
            <Col md={2} sm={2}>
              <Row>
                {story.videoKeySet && story.videoKeySet.map(videoKey => 
                  <ul key={videoKey}>
                    ({videoKey})
                    <a onClick={() => handleRemoveVideo(story.id, videoKey)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16" style={{color: 'red'}}>
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                      </svg>
                    </a>
                  </ul>
                )}
              </Row>
              <Row className='image-options'>
                {videoList && videoList.map(videoKey => 
                  <ul key={videoKey} className='my-0'>
                    ({videoKey})
                    <a onClick={() => handleAddVideo(story.id, videoKey)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square-fill" viewBox="0 0 16 16" style={{color: 'green'}}>
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0"/>
                      </svg>
                    </a>
                    </ul>
                )}
              </Row>
            </Col>
            <Col md={1} sm={1} className='justify-content-center'>
              <button className="btn btn-danger" onClick={() => deleteStory(story.id)}>Delete</button>
            </Col>
          </Row>
        ))}
      </Col>
      </>
  );
};

export default StoriesTable;
