import React from 'react';
import './style/WorkInProgress.css';

const WorkInProgress = () => {
  return (
    /* From Uiverse.io by Nawsome */ 
    <div className='page-container display-grid'>
        <h3>WORK IN PROGRESS</h3>
        <div class="typewriter">
            <div class="slide"><i></i></div>
            <div class="paper"></div>
            <div class="keyboard"></div>
        </div>
    </div>
  );
};

export default WorkInProgress;