import React, { useState } from 'react';
import FormData from 'form-data';
import { ADD_VIDEO, MAX_FILE_SIZE_MB, TOKEN_KEY } from '../config/Constants';
import { axiosInstance, setAuthToken } from './AxiosInstance';
import { Col, Container, Row } from 'react-bootstrap';
import Loading from './Loading';

const AddVideo = ({setErrorMsg}) => {
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [fileError, setFileError] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Access the File object
        if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            setFileError('File size exceeds ' +MAX_FILE_SIZE_MB +' MB limit.');
            setFile(null);
          return;
        }
        setFile(file);
    };
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        if(file == null || file.size < 0){
            setFileError('File is empty');
            setFile(null);
            return;
        }
        setIsLoading(true);
        setFileError(null);

        const formData = new FormData();
        if (file) {
            formData.append('file', file);
        }

        try {
            setAuthToken(localStorage.getItem(TOKEN_KEY));
            await axiosInstance.post(ADD_VIDEO, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (error) {
            setErrorMsg('Error uploading video file');
        } finally {
            setIsLoading(false);
        }
    };

    if(isLoading === true) return <Loading />;
    return (
        <div className="page-container display-grid" >
            <div className="max-content" >
            <form onSubmit={handleSubmit}>
                        <label htmlFor="file">Image (Max {MAX_FILE_SIZE_MB} MB)</label>
                        <input
                            type="file"
                            className="form-control my-2"
                            id="file"
                            accept="video/*"
                            onChange={handleFileChange}
                        />
                        {fileError && <p className="text-danger">{fileError}</p>}

                        <button type="submit" className="btn btn-primary my-2" disabled={isLoading}>
                            {isLoading ? 'Uploading....' : 'Add Video'}
                        </button>
          </form>
          </div>
        </div>
    );
};

export default AddVideo;