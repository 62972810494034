import React from "react";
import './style/CustomButton.css';

const CustomButton = ({buttonName, height, weight, onClick}) => {
    if(buttonName == null){
        buttonName = "download";
    }
    if(height == null){
        height = '100%';
    }
    if(weight == null){
        weight = '100%';
    }
    return (
        <button className="button" style={{height: "100%", width: "100%"}} onClick={onClick}>
            <svg
                strokeLinejoin="round"
                strokeLinecap="round"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                height={height}
                width={weight}
                className="button__icon"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path fill="none" d="M0 0h24v24H0z" stroke="none"></path>
                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                <path d="M7 11l5 5l5 -5"></path>
                <path d="M12 4l0 12"></path>
            </svg>
            <span className="button__text">{buttonName}</span>
        </button>

    );
};

export default CustomButton;