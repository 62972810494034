import React, { useState, useEffect } from 'react';
import './App.css'; // Assuming you have CSS for styling
import Notification from './components/Notification';
import { IS_SERVER_UP, CURRENT_USER, TOKEN_KEY, CHECK_TOKEN_EXIST_INTERVAL} from './config/Constants';
import axios from 'axios';
import MaintenancePage from './components/MaintenancePage';
import Loading from './components/Loading';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import StoryPage from './components/StoryPage';
import NavBar from './components/NavBar';
import { axiosInstance, setAuthToken } from './components/AxiosInstance';
import Register from './components/Register';
import AdminPage from './components/AdminPage';
import ForgotPassword from './components/ForgotPassword';
import ControllerApp from './components/ControllerApp';
import Login from './components/Login';
import Message from './components/Message';

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [serverError, setServerError] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [activePage, setActivePage] = useState(null);
    const [logoImageSrc, setLogoImageSrc] = useState(null);
    const [messageTabImageSrc, setMessageTabImageSrc] = useState(null);
    const [navBarLoyaltyIconSrc, setNavBarLoyaltyIconSrc] = useState(null);
    const [ifSuccess, setIfSuccess] = useState(false);
    const [serverStatusData, _] = useState(window.__PRELOADED_DATA__);

    setInterval(() => {
        if(localStorage.getItem(TOKEN_KEY) == null){
            setIsLoggedIn(false);
        }
    }, CHECK_TOKEN_EXIST_INTERVAL);

    const fetchServerStatus = async () => {
        try {
            const resp = await axios.get(IS_SERVER_UP);
            // if(resp.status === 200){
            //     setUiElements(resp.data);
            //     document.documentElement.style.setProperty('--website-bg-link', `url("${resp.data.UI_BACKGROUND_IMAGE_KEY}")`);
            // }
        } catch (error) {
            setServerError(error);
        }
    };
    useEffect(() => {
        fetchServerStatus();
    }, []);

    // const setUiElements = (data) => {
    //     setLogoImageSrc(data.UI_LOGO_IMAGE_KEY);
    //     setMessageTabImageSrc(data.UI_MESSAGE_TAB_BACKGROUND_IMAGE_KEY);
    //     setNavBarLoyaltyIconSrc(data.NAV_BAR_ICON_LOYALTY_TAB);
    // }

    const fetchUser = async (tokenKey) => {
        try{
            setAuthToken(tokenKey);
            const currentUserResp = await axiosInstance.get(CURRENT_USER);
            if(currentUserResp.status === 200){
                setErrorMsg(null);
                setUser(currentUserResp.data);
                setIsLoggedIn(true);
            } else {
                setErrorMsg(currentUserResp.data);
                setUser(null);
                setIsLoggedIn(false);
                setActivePage(null);
            }
        } catch(error){
            setErrorMsg(error.data);
            setUser(null);
            setIsLoggedIn(false);
            setActivePage(null);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setActivePage(null);
        let tokenKey = localStorage.getItem(TOKEN_KEY);
        if(tokenKey == null){
            setLoading(false);
            return;
        }
        fetchUser(tokenKey);
    }, [isLoggedIn]);

    if (loading) return <Loading />;
    if (serverError) return <MaintenancePage />;

    const renderElement = (Component, props) => {
        return isLoggedIn ? <Component {...props} /> : <Login setIsLoggedIn={setIsLoggedIn} setErrorMsg={setErrorMsg} logoImageSrc={logoImageSrc} setIfSuccess={setIfSuccess} />;
    };
  
    return (
        <Router>
            <div className="App">
                <NavBar 
                    activePage={activePage} 
                    updateActivePage={setActivePage} 
                    user={user} 
                    setIsLoggedIn={setIsLoggedIn}
                    setUser={setUser}
                    logoImageSrc={logoImageSrc}
                    navBarLoyaltyIconSrc={navBarLoyaltyIconSrc}
                />
                <Notification errorMsg={errorMsg} setErrorMsg={setErrorMsg} ifSuccess={ifSuccess} setIfSuccess={setIfSuccess} />
                <Routes>
                    <Route path="/" element={renderElement(StoryPage, { setErrorMsg, activePage })} />
                    <Route path="/admin/*" element={renderElement(AdminPage, { setErrorMsg })} />
                    <Route path="/controllerApp" element={renderElement(ControllerApp, { setErrorMsg, activePage })} />
                    <Route path="/messages" element={renderElement(Message, { setErrorMsg, activePage, messageTabImageSrc })} />
                    <Route path="/register" element={<Register setErrorMsg={setErrorMsg} setIfSuccess={setIfSuccess} />} />
                    <Route path="/forgot-password" element={<ForgotPassword setErrorMsg={setErrorMsg} />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;