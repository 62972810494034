import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import CustomButton from "./CustomButton";
import { MESSAGE_PAGE_BACKGROUND_IMAGE, MESSAGES_PAGE_LINKS_API, TOKEN_KEY } from "../config/Constants";
import { axiosInstance, setAuthToken } from "./AxiosInstance";
import Loading from "./Loading";

const Message = ({ activePage, setErrorMsg, messageTabImageSrc }) => {
    const[linkSet, setLinkSet] = useState(null);
    const[loading, setLoading] = useState(true);
    setAuthToken(localStorage.getItem(TOKEN_KEY));
    const fetchData = async () => {
        try {
          const storyResp = await axiosInstance.get(MESSAGES_PAGE_LINKS_API);
          if (storyResp.status === 200) {
            setLinkSet(storyResp.data); 
          } else {
            setErrorMsg(storyResp.data);
          }
        } catch (error) {   
          if (error.response.status === 403) {
            setErrorMsg("You don't have access to this page");
          } else if (error.response.status === 423) {
            setErrorMsg(error.response.data);
          } else {
            setErrorMsg("Failed to load the page");
          }
        } finally {
          setLoading(false);
        }
      };
    useEffect(()=> {
        if(activePage !== 6){
            return;
        }
        fetchData();
    }, [activePage]);
    if (loading || linkSet == null) return <Loading />;
    return (
        <Container className="page-container display-grid" 
            style={{
              // backgroundImage: `url(${messageTabImageSrc})`,
              backgroundImage: `url(${MESSAGE_PAGE_BACKGROUND_IMAGE})`,
              minHeight: "60vh",
              maxHeight: "max-content",
              minWidth: "70vw",
              maxWidth: "max-content",
              backgroundSize: "cover",
            }}
        >
            <div>
            {linkSet && Object.entries(linkSet).map(([name, link]) => (
                    <div key={name} className="mb-2" >
                        <CustomButton buttonName={name} onClick={() => window.location.href = link} />
                    </div>
                ))
            }
            </div>
        </Container>
    );
};

export default Message;